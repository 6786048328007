<template>
  <specialty-accordion-input
      v-model="value"
      @input="$emit('input', $event)"
      @expand="$emit('expand' , $event)"
      :specialty-id="specialtyId"
      :lang="$store.state.wsu_structure.selectedLang"
      title="wsu_structure.specialty.program.title"
      type="program"
      :blank-element="blankElement"
  >

    <template #item="{item}">
      <img :src="item.img"
             height="52"
             width="52"
             style="border-radius: 50%; object-fit: cover ; min-width:52px "
             alt=""
             class="mr-4 my-2"
      />
      <div class="py-3">
        <h5 class="wsDARKER shorten-text">{{ item.text }}</h5>
      </div>
    </template>


    <template #editor="{entityData}">
      <h5 class="font-weight-regular wsACCENT mb-2">
        {{ $t('wsu_structure.specialty.enroll_committee.img') }}
      </h5>
      <image-param-picker
          v-model="entityData.img"
          no-border
          class="mb-5"
          disable-portal
      />
      <!-- Name-->
      <ws-text-field
          :label="$t('HumanName')"
          :placeholder="$t('EnterHumanName')"
          v-model="entityData.text"
          class="mb-5"
          area
      />



    </template>

  </specialty-accordion-input>
</template>

<script>
import specialtyAccordionInput from "@modules/wsu_structure/components/description/specialtyAccordionInput.vue";
import imageParamPicker from "@/components/AvalonEditor/UI/imageParamPicker.vue";

export default {
  name: "descriptionSpecialtyProgram",
  components: {imageParamPicker, specialtyAccordionInput},
  props : {
    value : {
      type : Object,
      default() {
        return {}
      }
    },
    specialtyId : {
      type : String,
    }
  },
  data() {
    return {
      blankElement : {
        sphere : '',
        positions : '',
        img : ''
      }
    }
  },
  methods : {

  }
}
</script>



<style scoped>

</style>